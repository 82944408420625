/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { Observable, Subscription } from 'rxjs';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { UserDTO } from '../dto/user.dto';
import { UserService } from '../service/user.service';
import firebase from 'firebase/compat/app';
import { TransitionCheckState } from '@angular/material/checkbox';

export interface UserState {
    user: ObjectWithLoaderDTO<UserDTO>;
}

@Injectable()
export class UserStore extends NgSimpleStateBaseStore<UserState> {
    private authState: Observable<firebase.User>;
    private currentUser: firebase.User = null;
    private auth$: Subscription;

    constructor(
        injector: Injector,
        private userService: UserService,
        private afAuth: AngularFireAuth
    ) {
        super(injector);
        this.authState = this.afAuth.authState;

        this.auth$ = this.authState.subscribe({
            next: async (user) => {
                if (user) {
                    this.currentUser = user;
                    this.getUserFromApp(user?.uid);
                } else {
                    this.currentUser = null;
                    this.setState((state) => ({
                        user: { data: null, status: LoadStatus.success },
                    }));
                }
            },
            error: async (e) => {
                this.currentUser = null;
                this.setState((state) => ({
                    user: { data: null, status: LoadStatus.success },
                }));
            },
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.auth$.unsubscribe();
    }

    initialState(): UserState {
        return {
            user: new ObjectWithLoaderDTO<UserDTO>(),
        };
    }

    getUser(): Observable<ObjectWithLoaderDTO<UserDTO>> {
        return this.selectState((state) => state.user);
    }

    async isUserLoggedIn(): Promise<boolean> {
        return this.currentUser != null;
    }

    async doLogin({ userName, password }): Promise<void> {
        try {
            this.setState((state) => ({
                user: { ...state.user, status: LoadStatus.loading },
            }));
            try {
                await this.afAuth.signOut();
            } catch (e) {}
            await this.afAuth.signInWithEmailAndPassword(userName, password);
        } catch (e) {
            this.setState((state) => ({
                user: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
            throw e;
        }
    }

    async doSignUp(userCreation: any): Promise<void> {
        try {
            console.log('CREATING USER');
            this.setState((state) => ({
                user: { ...state.user, status: LoadStatus.loading },
            }));
            console.log('CREATING ON FB USER');
            const credentials =
                await this.afAuth.createUserWithEmailAndPassword(
                    userCreation.email,
                    userCreation.password
                );

            console.log('CREATED ON FB USER', credentials);
            if (credentials) {
                console.log('SAVING ON BE', {
                    ...userCreation,
                    federatedId: credentials.user.uid,
                    federatedProvider: 'FIREBASE',
                    isActive: true,
                    password: null,
                });
                const user = await this.userService.save({
                    ...userCreation,
                    federatedId: credentials.user.uid,
                    federatedProvider: 'FIREBASE',
                    isActive: true,
                    password: null,
                });

                console.log('SAVED', user);
                if (user) {
                    setTimeout(() => {
                        console.log('LOGIN', {
                            userName: userCreation.email,
                            password: userCreation.password,
                        });
                        this.doLogin({
                            userName: userCreation.email,
                            password: userCreation.password,
                        });
                    }, 500);
                }
            }
        } catch (e) {
            this.setState((state) => ({
                user: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
            throw e;
        }
    }

    async doLogOut(): Promise<void> {
        await this.afAuth.signOut();
        this.setState((state) => ({
            user: { data: null, status: LoadStatus.none },
        }));
    }

    private async getUserFromApp(uid: string): Promise<void> {
        this.setState((state) => ({
            user: { ...state.user, status: LoadStatus.loading },
        }));
        try {
            const user = await this.userService.getUserByFederatedId(uid);
            this.setState((state) => ({
                user: { data: user, status: LoadStatus.success },
            }));
        } catch (e) {
            this.setState((state) => ({
                user: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
            throw e;
        }
    }
}
