import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { UserStore } from 'app/core/store/user.store';
import { LoadStatus } from 'app/core/dto/object-with-loader.dto';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private userStore: UserStore, private _router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._check();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this._check();
    }

    private async _check(): Promise<boolean> {
        let userWithLoad = await firstValueFrom(this.userStore.getUser());

        while (
            userWithLoad.status === LoadStatus.none ||
            userWithLoad.status === LoadStatus.loading
        ) {
            userWithLoad = await firstValueFrom(this.userStore.getUser());
        }

        if (userWithLoad.data && userWithLoad.data.id) {
            this._router.navigate(['']);
            return false;
        }

        return true;
    }
}
