import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { CompanyDTO } from '../dto/company.dto';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ProductCategoryDTO } from '../dto/product-category.dto';
import { ProductDTO } from '../dto/product.dto';
import { CompanyService } from '../service/company.service';
import { ProductCategoryService } from '../service/product-category.service';
import { ProductService } from '../service/product.service';
import { CompanyStore } from './company.store';
import { UserStore } from './user.store';

export interface ProuctCategoryState {
    categories: ObjectWithLoaderDTO<ProductDTO[]>;
}

@Injectable()
export class ProductCategoryStore extends NgSimpleStateBaseStore<ProuctCategoryState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private productCategoryService: ProductCategoryService
    ) {
        super(injector);
    }

    initialState(): ProuctCategoryState {
        return {
            categories: new ObjectWithLoaderDTO<ProductCategoryDTO[]>(),
        };
    }

    getCategories(): Observable<ObjectWithLoaderDTO<ProductDTO[]>> {
        return this.selectState((state) => state.categories);
    }

    async fetchCategories(): Promise<void> {
        try {
            this.setState((state) => ({
                categories: { data: null, status: LoadStatus.loading },
            }));

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                this.setState((state) => ({
                    categories: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Nenhuma empresa selectionada',
                    },
                }));
            }

            const categories = await this.productCategoryService.getAll();

            this.setState((state) => ({
                categories: {
                    data: categories,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                categories: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }
}
