import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthModule } from 'app/core/auth/auth.module';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { AutofocusDirective } from './directive/autofocus.directive';
import { MarkdownPipe } from './pipe/markdown.pipe';

@NgModule({
    imports: [AuthModule, IconsModule, TranslocoCoreModule],
    declarations: [AutofocusDirective, MarkdownPipe],
    exports: [AutofocusDirective, MarkdownPipe],
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        // if (parentModule) {
        //     throw new Error(
        //         'CoreModule has already been loaded. Import this module in the AppModule only.'
        //     );
        // }
    }

    static forRoot(): any {
        return {
            ngModule: CoreModule,
            providers: [
                AuthModule,
                IconsModule,
                TranslocoCoreModule,
                AutofocusDirective,
            ],
        };
    }

    static forChild(): any {
        return {
            ngModule: CoreModule,
            providers: [MarkdownPipe],
        };
    }
}
