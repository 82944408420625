import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, map, retry } from 'rxjs';
import { ChatMessageDTO } from '../dto/chat-message.dto';
import { ChatSessionCompactDTO, ChatSessionDTO } from '../dto/chat-session.dto';
import { ServiceCategoryDTO } from '../dto/service-category.dto';

@Injectable({
    providedIn: 'root',
})
export class ChatMessageService {
    constructor(private httpClient: HttpClient) {}

    getAllBySessionId(sessionId: number): Promise<ChatMessageDTO[]> {
        const chats$ = this.httpClient
            .get<ChatMessageDTO[]>(
                `${environment.backBaseUrl}/chat-message/session/${sessionId}`
            )
            .pipe(retry(3))
            .pipe(map((res) => res.map((each) => new ChatMessageDTO(each))));

        return lastValueFrom(chats$);
    }

    getById(id: number): Promise<ChatMessageDTO> {
        const chat$ = this.httpClient
            .get<ChatMessageDTO>(
                `${environment.backBaseUrl}/chat-message/id/${id}`
            )
            .pipe(retry(3))
            .pipe(map((res) => new ChatMessageDTO(res)));
        return lastValueFrom(chat$);
    }

    save(data: any): Promise<ChatMessageDTO> {
        const chat$ = this.httpClient
            .put<ChatMessageDTO>(
                `${environment.backBaseUrl}/chat-message`,
                data
            )
            .pipe(retry(3));
        return lastValueFrom(chat$);
    }
}
