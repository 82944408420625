/* eslint-disable max-len */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marked } from 'marked';

@Pipe({
    name: 'markdown',
})
export class MarkdownPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: any, args?: any[]): any {
        marked.use({ renderer: { listitem: this.listitem } });
        if (value && value.length > 0) {
            return this.sanitizer.bypassSecurityTrustHtml(marked(value));
        }
        return value;
    }

    listitem(this: any, text: string, task: boolean, checked: boolean): string {
        return `<div class="mt-2 flex items-center leading-5 text-md text-secondary">
                    <mat-icon class="icon-size-5 text-hint">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    </mat-icon>
                    <div class="ml-1.5">${text}</div>
                </div>`;
    }
}
