import { PermissionDTO } from './permission.dto';

export class RoleDTO {
    id?: number;
    name?: string;
    friendlyName?: string;
    description?: string;
    permissions?: PermissionDTO[];

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}
