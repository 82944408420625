import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, map, retry } from 'rxjs';
import { ServiceCategoryDTO } from '../dto/service-category.dto';
import { RoleDTO } from '../dto/role.dto';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    constructor(private httpClient: HttpClient) {}

    getAll(): Promise<RoleDTO[]> {
        const products$ = this.httpClient
            .get<RoleDTO[]>(`${environment.backBaseUrl}/role`)
            .pipe(retry(3))
            .pipe(map((res) => res.map((each) => new RoleDTO(each))));
        return lastValueFrom(products$);
    }
}
