import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, Observable, retry, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CompanyDTO } from '../dto/company.dto';
import { PhotoDTO } from '../dto/photo.dto';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    constructor(private httpClient: HttpClient) {}

    getCompaniesStripeLoginLink(companyId: number): Promise<any> {
        const params = new HttpParams().set('linkToGo', window.location.href);
        const loginLink$ = this.httpClient
            .get(
                `${environment.backBaseUrl}/company/stripe/login/${companyId}`,
                {
                    params: params,
                    responseType: 'text',
                }
            )
            .pipe(retry(3));
        return lastValueFrom(loginLink$);
    }

    getCompaniesByUserId(userId: number): Promise<CompanyDTO[]> {
        const companies$ = this.httpClient
            .get<CompanyDTO[]>(
                `${environment.backBaseUrl}/company/user/${userId}`
            )
            .pipe(retry(3));
        return lastValueFrom(companies$);
    }

    getCompanyById(companyId: number): Promise<CompanyDTO> {
        const company$ = this.httpClient
            .get<CompanyDTO>(
                `${environment.backBaseUrl}/company/id/${companyId}`
            )
            .pipe(retry(3));
        return lastValueFrom(company$);
    }

    save(company: any): Promise<CompanyDTO> {
        const company$ = this.httpClient
            .put<CompanyDTO>(`${environment.backBaseUrl}/company`, company)
            .pipe(retry(3));
        return lastValueFrom(company$);
    }

    update(company: any): Promise<CompanyDTO> {
        const company$ = this.httpClient
            .post<CompanyDTO>(`${environment.backBaseUrl}/company`, company)
            .pipe(retry(3));
        return lastValueFrom(company$);
    }

    delete(companyId: number): Promise<void> {
        return lastValueFrom(
            this.httpClient
                .delete<void>(
                    `${environment.backBaseUrl}/company/id/${companyId}`
                )
                .pipe(retry(3))
        );
    }

    addPhoto(companyId: number, photo: string): Promise<PhotoDTO> {
        const company$ = this.httpClient
            .put<PhotoDTO>(`${environment.backBaseUrl}/company-photo`, {
                companyId: companyId,
                base64: photo,
            })
            .pipe(retry(3));
        return lastValueFrom(company$);
    }

    removePhoto(photoId: number): Promise<void> {
        const company$ = this.httpClient
            .delete<void>(
                `${environment.backBaseUrl}/company-photo/id/${photoId}`
            )
            .pipe(retry(3));
        return lastValueFrom(company$);
    }

    getUsersByCompany(companyId: number): Promise<any[]> {
        const company$ = this.httpClient
            .get<any[]>(
                `${environment.backBaseUrl}/company/id/${companyId}/users`
            )
            .pipe(retry(3));
        return lastValueFrom(company$);
    }

    addUserToCompany(data: {
        userId?;
        userEmail?;
        roleId;
        companyId;
    }): Promise<void> {
        return lastValueFrom(
            this.httpClient
                .post<void>(
                    `${environment.backBaseUrl}/company/id/${data.companyId}/user`,
                    {
                        user: { id: data.userId, email: data.userEmail },
                        role: { id: data.roleId },
                    }
                )
                .pipe(retry(3))
        );
    }

    deleteUserFromCompany(userId: number, companyId: number): Promise<void> {
        return lastValueFrom(
            this.httpClient
                .delete<void>(
                    `${environment.backBaseUrl}/company/id/${companyId}/user/${userId}`
                )
                .pipe(retry(3))
        );
    }
}
