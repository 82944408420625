import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { Observable, firstValueFrom } from 'rxjs';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ServiceDTO } from '../dto/service.dto';
import { ServiceService } from '../service/service.service';
import { CompanyStore } from './company.store';
import { filter } from 'lodash';

export interface ServiceState {
    services: ObjectWithLoaderDTO<ServiceDTO[]>;
    selectedService: ObjectWithLoaderDTO<ServiceDTO>;
    savingService: ObjectWithLoaderDTO<ServiceDTO>;
}

@Injectable()
export class ServiceStore extends NgSimpleStateBaseStore<ServiceState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private serviceService: ServiceService
    ) {
        super(injector);
    }

    initialState(): ServiceState {
        return {
            services: new ObjectWithLoaderDTO<ServiceDTO[]>(),
            selectedService: new ObjectWithLoaderDTO<ServiceDTO>(),
            savingService: new ObjectWithLoaderDTO<ServiceDTO>(),
        };
    }

    getServices(): Observable<ObjectWithLoaderDTO<ServiceDTO[]>> {
        return this.selectState((state) => state.services);
    }

    getSelectedService(): Observable<ObjectWithLoaderDTO<ServiceDTO>> {
        return this.selectState((state) => state.selectedService);
    }

    getSavingService(): Observable<ObjectWithLoaderDTO<ServiceDTO>> {
        return this.selectState((state) => state.savingService);
    }

    async fetchServices(): Promise<void> {
        try {
            this.setState((state) => ({
                services: { data: null, status: LoadStatus.loading },
            }));

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                this.setState((state) => ({
                    services: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Nenhuma empresa selectionada',
                    },
                }));
            }

            const services = await this.serviceService.getByCompanyId(
                selectedCompany.data?.id
            );

            this.setState((state) => ({
                services: {
                    data: services.content,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                services: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async cleanService(): Promise<void> {
        this.setState((state) => ({
            selectedService: { data: null, status: LoadStatus.success },
        }));
    }

    async fetchService(id: number): Promise<void> {
        try {
            console.log('FETHCING');
            this.setState((state) => ({
                selectedService: { data: null, status: LoadStatus.loading },
            }));

            const service = await this.serviceService.getById(id);
            console.log('GOT IT');
            this.setState((state) => ({
                selectedService: {
                    data: service,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            console.log('ERROR', e);
            this.setState((state) => ({
                selectedService: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async saveService(serviceCreationData: any): Promise<ServiceDTO> {
        try {
            this.setState((state) => ({
                savingService: {
                    data: null,
                    status: LoadStatus.loading,
                },
            }));

            const company = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (!company?.data?.id) {
                this.setState((state) => ({
                    savingService: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Empresa não selecionada',
                    },
                }));
                return;
            }

            const service = await this.serviceService.save({
                ...serviceCreationData,
                company: {
                    id: company?.data?.id,
                },
            });

            this.fetchServices();

            this.setState((state) => ({
                savingService: { data: service, status: LoadStatus.success },
            }));

            return service;
        } catch (e) {
            this.setState((state) => ({
                savingService: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async update(serviceData: any): Promise<ServiceDTO> {
        try {
            const service = await this.serviceService.update({
                ...serviceData,
            });

            this.fetchServices();

            this.setState((state) => ({
                selectedService: { data: service, status: LoadStatus.success },
                services: {
                    ...state.services,
                    data: state.services.data.map((each) =>
                        each.id === service.id ? service : each
                    ),
                },
            }));

            return service;
        } catch (e) {
            this.setState((state) => ({
                selectedService: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async deleteSelected(): Promise<void> {
        const selectedService = await firstValueFrom(
            this.selectState((state) => state.selectedService.data)
        );

        if (!selectedService?.id) {
            return;
        }

        await this.serviceService.delete(selectedService.id);

        this.setState((state) => ({
            selectedService: {
                status: LoadStatus.success,
            },
            services: {
                ...state.services,
                data: filter(state.services.data, (item) => item.id !== selectedService.id),
            },
        }));
    }

    async addPhotoOnSelected(photo: string): Promise<void> {
        const selectedService = await firstValueFrom(
            this.selectState((state) => state.selectedService.data)
        );

        if (!selectedService?.id) {
            return;
        }

        await this.serviceService.addPhoto(selectedService.id, photo);
        this.fetchService(selectedService.id);
    }

    async removePhotoOnSelected(photoId: number): Promise<void> {
        const selectedService = await firstValueFrom(
            this.selectState((state) => state.selectedService.data)
        );

        if (!selectedService?.id) {
            return;
        }

        await this.serviceService.removePhoto(photoId);
        this.fetchService(selectedService.id);
    }
}
