import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ServiceCategoryDTO } from '../dto/service-category.dto';
import { VerificationDTO } from '../dto/verification.dto';
import { VerificationService } from '../service/verification.service';
import { CompanyStore } from './company.store';

export interface VerificationState {
    verifications: ObjectWithLoaderDTO<VerificationDTO[]>;
}

@Injectable()
export class VerificationStore extends NgSimpleStateBaseStore<VerificationState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private verificationService: VerificationService
    ) {
        super(injector);
    }

    initialState(): VerificationState {
        return {
            verifications: new ObjectWithLoaderDTO<VerificationDTO[]>(),
        };
    }

    getVerifications(): Observable<ObjectWithLoaderDTO<VerificationDTO[]>> {
        return this.selectState((state) => state.verifications);
    }

    async fetchVerifications(): Promise<void> {
        try {
            this.setState((state) => ({
                verifications: { data: null, status: LoadStatus.loading },
            }));

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                this.setState((state) => ({
                    verifications: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Nenhum disponível',
                    },
                }));
            }

            const categories = await this.verificationService.getAll();

            this.setState((state) => ({
                verifications: {
                    data: categories,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                verifications: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }
}
