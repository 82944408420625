import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { CompanyStore } from 'app/core/store/company.store';
import { CompanyDTO } from 'app/core/dto/company.dto';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    navigation: FuseNavigationItem[];
    company: CompanyDTO;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private companyStore: CompanyStore
    ) {}

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.companyStore
            .getSelectedCompanie()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {
                this.company = value.data;

                this.navigation = [
                    {
                        id: 'company',
                        title: 'Minha Empresa',
                        type: 'basic',
                        icon: 'heroicons_outline:home',
                        link: `/admin/company/${this.company?.id}/general`,
                        disabled: this.company?.id == null,
                    },
                    {
                        id: 'product',
                        title: 'Produtos',
                        type: 'basic',
                        icon: 'heroicons_outline:gift',
                        link: `/admin/company/${this.company?.id}/product`,
                        disabled: this.company?.id == null,
                    },
                    {
                        id: 'service',
                        title: 'Serviços',
                        type: 'basic',
                        icon: 'heroicons_outline:document',
                        link: `/admin/company/${this.company?.id}/service`,
                        disabled: this.company?.id == null,
                    },
                    {
                        id: 'company',
                        title: 'Relatórios',
                        type: 'basic',
                        icon: 'heroicons_outline:chart-pie',
                        link: `/admin/company/${this.company?.id}/summary`,
                        disabled: this.company?.id == null,
                    },
                    {
                        id: 'chat',
                        title: 'Chat',
                        type: 'basic',
                        icon: 'heroicons_outline:chat-alt',
                        link: `/admin/company/${this.company?.id}/chat`,
                        disabled: this.company?.id == null,
                    },
                ];
            });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name
            );

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
