import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry, tap } from 'rxjs';
import { ProductCategoryDTO } from '../dto/product-category.dto';
import { ProductDTO } from '../dto/product.dto';

@Injectable({
    providedIn: 'root',
})
export class ProductCategoryService {
    constructor(private httpClient: HttpClient) {}

    getAll(): Promise<ProductCategoryDTO[]> {
        const products$ = this.httpClient
            .get<ProductCategoryDTO[]>(
                `${environment.backBaseUrl}/product-category`
            )
            .pipe(retry(3));
        return lastValueFrom(products$);
    }
}
