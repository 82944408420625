import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { Observable, firstValueFrom } from 'rxjs';
import { ChatMessageDTO } from '../dto/chat-message.dto';
import { CompanyBranchDTO } from '../dto/company-branch.dto';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ChatMessageService } from '../service/chat-message.service';
import { ChatSessionStore } from './chat-session.store';
import { CompanyStore } from './company.store';
import { ServiceStore } from './service.store';
import { UserStore } from './user.store';

export interface ChatMessageState {
    messages: ObjectWithLoaderDTO<ChatMessageDTO[]>;
    selectedMessage: ObjectWithLoaderDTO<ChatMessageDTO>;
}

@Injectable()
export class ChatMessageStore extends NgSimpleStateBaseStore<ChatMessageState> {
    constructor(
        injector: Injector,
        private chatMessageService: ChatMessageService,
        private chatSessionStore: ChatSessionStore,
        private userStore: UserStore,
        private companyStore: CompanyStore
    ) {
        super(injector);
    }

    initialState(): ChatMessageState {
        return {
            messages: new ObjectWithLoaderDTO<ChatMessageDTO[]>(),
            selectedMessage: new ObjectWithLoaderDTO<ChatMessageDTO>(),
        };
    }

    getMessages(): Observable<ObjectWithLoaderDTO<ChatMessageDTO[]>> {
        return this.selectState((state) => state.messages);
    }

    getSelectedMessage(): Observable<ObjectWithLoaderDTO<CompanyBranchDTO>> {
        return this.selectState((state) => state.selectedMessage);
    }

    cleanMessages(): void {
        this.setState((state) => ({
            messages: new ObjectWithLoaderDTO<ChatMessageDTO[]>(),
        }));
    }

    newMessage(message: ChatMessageDTO): void {
        this.setState((state) => ({
            messages: {
                ...state.messages,
                data: [...state.messages.data, message],
            },
        }));
    }

    async fetchMessages(sessionId: number): Promise<void> {
        try {
            this.setState((state) => ({
                messages: { data: null, status: LoadStatus.loading },
            }));

            const messages: ChatMessageDTO[] =
                await this.chatMessageService.getAllBySessionId(sessionId);

            this.setState((state) => ({
                messages: {
                    data: messages,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                messages: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async fetchMessage(sessionId: number): Promise<void> {
        try {
            this.setState((state) => ({
                selectedMessage: {
                    data: null,
                    status: LoadStatus.loading,
                },
            }));

            const session = await this.chatMessageService.getById(sessionId);

            this.setState((state) => ({
                selectedMessage: { data: session, status: LoadStatus.success },
            }));
        } catch (e) {
            this.setState((state) => ({
                selectedMessage: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async save(message): Promise<void> {
        try {
            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            const selectedSesssion = await firstValueFrom(
                this.chatSessionStore.getSelectedSession()
            );

            const user = await firstValueFrom(this.userStore.getUser());

            if (
                selectedCompany.data?.id == null ||
                selectedSesssion.data?.id == null ||
                user.data?.id == null
            ) {
                return;
            }

            this.chatMessageService.save({
                message: message,
                userId: user.data?.id,
                chatSessionId: selectedSesssion.data?.id,
                companyId: selectedCompany.data?.id,
            });
        } catch (e) {}
    }
}
