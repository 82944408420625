import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry } from 'rxjs';
import { ServiceCategoryDTO } from '../dto/service-category.dto';

@Injectable({
    providedIn: 'root',
})
export class ServiceCategoryService {
    constructor(private httpClient: HttpClient) {}

    getAll(): Promise<ServiceCategoryDTO[]> {
        const products$ = this.httpClient
            .get<ServiceCategoryDTO[]>(
                `${environment.backBaseUrl}/service-category`
            )
            .pipe(retry(3));
        return lastValueFrom(products$);
    }
}
