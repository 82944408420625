import { ChatSessionDTO } from './chat-session.dto';
import { CompanyDTO } from './company.dto';
import { UserDTO } from './user.dto';

export class ChatMessageDTO {
    id?: number;
    message?: any;
    imageUrl?: string;
    audioUrl?: string;
    replyChatMessage?: ChatMessageDTO;
    chatSession?: ChatSessionDTO;
    user?: UserDTO;
    company?: CompanyDTO;
    createdAt?: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    get isMine(): boolean {
        return this.chatSession?.user?.id === this.user?.id;
    }
}
