import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { RoleDTO } from '../dto/role.dto';
import { ServiceCategoryDTO } from '../dto/service-category.dto';
import { RoleService } from '../service/role.service';
import { ServiceCategoryService } from '../service/service-category.service';
import { CompanyStore } from './company.store';

export interface RoleState {
    roles: ObjectWithLoaderDTO<RoleDTO[]>;
}

@Injectable()
export class RoleStore extends NgSimpleStateBaseStore<RoleState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private roleService: RoleService
    ) {
        super(injector);
    }

    initialState(): RoleState {
        return {
            roles: new ObjectWithLoaderDTO<RoleDTO[]>(),
        };
    }

    getRoles(): Observable<ObjectWithLoaderDTO<ServiceCategoryDTO[]>> {
        return this.selectState((state) => state.roles);
    }

    async fetchRoles(): Promise<void> {
        try {
            this.setState((state) => ({
                roles: { data: null, status: LoadStatus.loading },
            }));

            const roles = await this.roleService.getAll();

            this.setState((state) => ({
                roles: {
                    data: roles,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                roles: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }
}
