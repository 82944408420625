import { Component, OnInit } from '@angular/core';
import { FuseLoadingService } from '@fuse/services/loading';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    /**
     * Constructor
     */
    constructor(private fuseLoadingService: FuseLoadingService) {}

    ngOnInit(): void {
        this.fuseLoadingService.setAutoMode(false);
    }
}
