import { Injectable, Injector } from '@angular/core';
import { filter } from 'lodash';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { CompanyBranchDTO } from '../dto/company-branch.dto';
import { CompanyDTO } from '../dto/company.dto';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { CompanyBranchService } from '../service/company-branch.service';
import { CompanyStore } from './company.store';

export interface CompanyBranchState {
    branches: ObjectWithLoaderDTO<CompanyBranchDTO[]>;
    savingBranch: ObjectWithLoaderDTO<CompanyBranchDTO>;
    selectedBranch: ObjectWithLoaderDTO<CompanyBranchDTO>;
}

@Injectable()
export class CompanyBranchStore extends NgSimpleStateBaseStore<CompanyBranchState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private companyBranchService: CompanyBranchService
    ) {
        super(injector);
    }

    initialState(): CompanyBranchState {
        return {
            branches: new ObjectWithLoaderDTO<CompanyBranchDTO[]>(),
            savingBranch: new ObjectWithLoaderDTO<CompanyBranchDTO>(),
            selectedBranch: new ObjectWithLoaderDTO<CompanyBranchDTO>(),
        };
    }

    getBranches(): Observable<ObjectWithLoaderDTO<CompanyBranchDTO[]>> {
        return this.selectState((state) => state.branches);
    }

    getSavingBranch(): Observable<ObjectWithLoaderDTO<CompanyBranchDTO>> {
        return this.selectState((state) => state.savingBranch);
    }

    getSelectedBranch(): Observable<ObjectWithLoaderDTO<CompanyBranchDTO>> {
        return this.selectState((state) => state.selectedBranch);
    }

    setSelectedBranch(branch: CompanyBranchDTO): void {
        this.setState((state) => ({
            selectedBranch: { data: branch, status: LoadStatus.none },
        }));
    }

    async fetchBranches(): Promise<void> {
        try {
            this.setState((state) => ({
                branches: { data: null, status: LoadStatus.loading },
            }));

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                this.setState((state) => ({
                    branches: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Nenhuma empresa selectionada',
                    },
                }));
            }

            const branches = await this.companyBranchService.getByCompayId(
                selectedCompany.data?.id
            );

            this.setState((state) => ({
                branches: {
                    data: branches,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                branches: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async save(companyBranchData: any): Promise<CompanyBranchDTO> {
        this.setState((state) => ({
            savingBranch: {
                data: null,
                status: LoadStatus.loading,
            },
        }));

        const company = await firstValueFrom(
            this.companyStore.getSelectedCompanie()
        );

        if (!company?.data?.id) {
            this.setState((state) => ({
                savingBranch: {
                    data: null,
                    status: LoadStatus.error,
                    message: 'Nenhuma empresa selecionada',
                },
            }));
            return;
        }

        const branch = await this.companyBranchService.save({
            ...companyBranchData,
            isActive: true,
            companyId: company?.data?.id,
        });

        this.setState((state) => ({
            savingBranch: { data: branch, status: LoadStatus.success },
        }));

        this.fetchBranches();

        return branch;
    }

    async delete(branchId: number): Promise<void> {
        await this.companyBranchService.delete(branchId);

        this.setState((state) => ({
            branches: {
                ...state.branches,
                data: filter(
                    state.branches.data,
                    (item) => item.id !== branchId
                ),
            },
        }));
    }

    async update(
        companyBranchData: CompanyBranchDTO
    ): Promise<CompanyBranchDTO> {
        this.setState((state) => ({
            savingBranch: {
                data: null,
                status: LoadStatus.loading,
            },
        }));

        try {
            companyBranchData = await this.companyBranchService.update({
                ...companyBranchData,
            });

            this.setState((state) => ({
                savingBranch: { data: null, status: LoadStatus.success },
                branches: {
                    ...state.branches,
                    data: [...state.branches.data].map((each) =>
                        each.id === companyBranchData.id
                            ? companyBranchData
                            : each
                    ),
                },
            }));
            return companyBranchData;
        } catch (e) {
            this.setState((state) => ({
                savingBranch: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
            return;
        }
    }
}
