import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry } from 'rxjs';
import { PhotoDTO } from '../dto/photo.dto';
import { ServiceDTO } from '../dto/service.dto';

@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    constructor(private httpClient: HttpClient) {}

    getByCompanyId(commpanyId: number): Promise<any> {
        const services$ = this.httpClient
            .get<any>(
                `${environment.backBaseUrl}/service/company/${commpanyId}?page=0&size=50`
            )
            .pipe(retry(3));
        return lastValueFrom(services$);
    }

    getById(id: number): Promise<ServiceDTO> {
        const service$ = this.httpClient
            .get<ServiceDTO>(`${environment.backBaseUrl}/service/id/${id}`)
            .pipe(retry(3));
        return lastValueFrom(service$);
    }

    save(service: any): Promise<ServiceDTO> {
        const service$ = this.httpClient
            .put<ServiceDTO>(`${environment.backBaseUrl}/service`, service)
            .pipe(retry(3));
        return lastValueFrom(service$);
    }

    update(service: any): Promise<ServiceDTO> {
        const service$ = this.httpClient
            .post<ServiceDTO>(`${environment.backBaseUrl}/service`, service)
            .pipe(retry(3));
        return lastValueFrom(service$);
    }

    delete(serviceId: number): Promise<void> {
        return lastValueFrom(
            this.httpClient
                .delete<void>(`${environment.backBaseUrl}/service/id/${serviceId}`)
                .pipe(retry(3))
        );
    }

    addPhoto(serviceId: number, photo: string): Promise<PhotoDTO> {
        const service$ = this.httpClient
            .put<PhotoDTO>(`${environment.backBaseUrl}/service-photo`, {
                serviceId: serviceId,
                base64: photo,
            })
            .pipe(retry(3));
        return lastValueFrom(service$);
    }

    removePhoto(photoId: number): Promise<void> {
        const service$ = this.httpClient
            .delete<void>(`${environment.backBaseUrl}/service-photo/id/${photoId}`)
            .pipe(retry(3));
        return lastValueFrom(service$);
    }
}
