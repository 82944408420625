import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry } from 'rxjs';
import { ChatSessionCompactDTO, ChatSessionDTO } from '../dto/chat-session.dto';

@Injectable({
    providedIn: 'root',
})
export class ChatSessionService {
    constructor(private httpClient: HttpClient) {}

    getByCompayId(companyId: number): Promise<ChatSessionCompactDTO[]> {
        const chats$ = this.httpClient
            .get<ChatSessionCompactDTO[]>(
                `${environment.backBaseUrl}/chat-session/company/${companyId}`
            )
            .pipe(retry(3));
        return lastValueFrom(chats$);
    }

    getById(id: number): Promise<ChatSessionDTO> {
        const chat$ = this.httpClient
            .get<ChatSessionDTO>(`${environment.backBaseUrl}/chat-session/id/${id}`)
            .pipe(retry(3));
        return lastValueFrom(chat$);
    }
}
