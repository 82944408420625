import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry, tap } from 'rxjs';
import { PhotoDTO } from '../dto/photo.dto';
import { ProductDTO } from '../dto/product.dto';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private httpClient: HttpClient) {}

    getProductsByCompanyId(companyId: number): Promise<any> {
        const products$ = this.httpClient
            .get<any>(
                `${environment.backBaseUrl}/product/company/${companyId}?page=0&size=50`
            )
            .pipe(retry(3));
        return lastValueFrom(products$);
    }

    getProductById(id: number): Promise<ProductDTO> {
        const product$ = this.httpClient
            .get<ProductDTO>(`${environment.backBaseUrl}/product/id/${id}`)
            .pipe(retry(3));
        return lastValueFrom(product$);
    }

    saveProduct(company: any): Promise<ProductDTO> {
        const product$ = this.httpClient
            .put<ProductDTO>(`${environment.backBaseUrl}/product`, company)
            .pipe(retry(3));
        return lastValueFrom(product$);
    }

    update(product: any): Promise<ProductDTO> {
        const product$ = this.httpClient
            .post<ProductDTO>(`${environment.backBaseUrl}/product`, product)
            .pipe(retry(3));
        return lastValueFrom(product$);
    }

    delete(productId: number): Promise<void> {
        return lastValueFrom(
            this.httpClient
                .delete<void>(`${environment.backBaseUrl}/product/id/${productId}`)
                .pipe(retry(3))
        );
    }

    addPhoto(productId: number, photo: string): Promise<PhotoDTO> {
        const product$ = this.httpClient
            .put<PhotoDTO>(`${environment.backBaseUrl}/product-photo`, {
                productId: productId,
                base64: photo
            })
            .pipe(retry(3));
        return lastValueFrom(product$);
    }

    removePhoto(photoId: number): Promise<void> {
        const product$ = this.httpClient
            .delete<void>(`${environment.backBaseUrl}/product-photo/id/${photoId}`)
            .pipe(retry(3));
        return lastValueFrom(product$);
    }
}
