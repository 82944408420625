import { Injectable } from '@angular/core';

export enum LoadStatus {
    none = 'NONE',
    loading = 'LOADING',
    success = 'SUCCESS',
    error = 'ERROR',
}

export class ObjectWithLoaderDTO<T> {
    constructor(
        public data?: T,
        public status: LoadStatus = LoadStatus.none,
        public message?: string
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class LoadignHelper {
    isLoading = (status: LoadStatus): boolean => status === LoadStatus.loading;
    isError = (status: LoadStatus): boolean => status === LoadStatus.error;
    isSuccess = (status: LoadStatus): boolean => status === LoadStatus.success;
}
