import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry } from 'rxjs';
import { UserDTO } from '../dto/user.dto';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private httpClient: HttpClient) {}

    getUserByFederatedId(federatedId: string): Promise<UserDTO> {
        const user$ = this.httpClient
            .get<UserDTO>(
                `${environment.backBaseUrl}/user/federated/${federatedId}`
            )
            .pipe(retry(3));
        return lastValueFrom(user$);
    }

    save(data: any): Promise<UserDTO> {
        const user$ = this.httpClient
            .put<UserDTO>(`${environment.backBaseUrl}/user`, data)
            .pipe(retry(3));
        return lastValueFrom(user$);
    }
}
