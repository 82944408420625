import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ServiceCategoryDTO } from '../dto/service-category.dto';
import { ServiceCategoryService } from '../service/service-category.service';
import { CompanyStore } from './company.store';

export interface ServiceCategoryState {
    categories: ObjectWithLoaderDTO<ServiceCategoryDTO[]>;
}

@Injectable()
export class ServiceCategoryStore extends NgSimpleStateBaseStore<ServiceCategoryState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private serviceCategoryService: ServiceCategoryService
    ) {
        super(injector);
    }

    initialState(): ServiceCategoryState {
        return {
            categories: new ObjectWithLoaderDTO<ServiceCategoryDTO[]>(),
        };
    }

    getCategories(): Observable<ObjectWithLoaderDTO<ServiceCategoryDTO[]>> {
        return this.selectState((state) => state.categories);
    }

    async fetchCategories(): Promise<void> {
        try {
            this.setState((state) => ({
                categories: { data: null, status: LoadStatus.loading },
            }));

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                this.setState((state) => ({
                    categories: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Nenhuma empresa selectionada',
                    },
                }));
            }

            const categories = await this.serviceCategoryService.getAll();

            this.setState((state) => ({
                categories: {
                    data: categories,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                categories: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }
}
