import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ProductDTO } from '../dto/product.dto';
import { ProductService } from '../service/product.service';
import { CompanyStore } from './company.store';
import { filter } from 'lodash';

export interface ProuctState {
    products: ObjectWithLoaderDTO<ProductDTO[]>;
    selectedProduct: ObjectWithLoaderDTO<ProductDTO>;
    savingProduct: ObjectWithLoaderDTO<ProductDTO>;
}

@Injectable()
export class ProductStore extends NgSimpleStateBaseStore<ProuctState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private productService: ProductService
    ) {
        super(injector);
    }

    initialState(): ProuctState {
        return {
            products: new ObjectWithLoaderDTO<ProductDTO[]>(),
            selectedProduct: new ObjectWithLoaderDTO<ProductDTO>(),
            savingProduct: new ObjectWithLoaderDTO<ProductDTO>(),
        };
    }

    getProducts(): Observable<ObjectWithLoaderDTO<ProductDTO[]>> {
        return this.selectState((state) => state.products);
    }

    getSelectedProduct(): Observable<ObjectWithLoaderDTO<ProductDTO>> {
        return this.selectState((state) => state.selectedProduct);
    }

    getSavingProduct(): Observable<ObjectWithLoaderDTO<ProductDTO>> {
        return this.selectState((state) => state.savingProduct);
    }

    async fetchProducts(): Promise<void> {
        try {
            this.setState((state) => ({
                products: { data: null, status: LoadStatus.loading },
            }));

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                this.setState((state) => ({
                    products: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Nenhuma empresa selectionada',
                    },
                }));
            }

            const products = await this.productService.getProductsByCompanyId(
                selectedCompany.data?.id
            );

            this.setState((state) => ({
                products: {
                    data: products.content,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                products: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async cleanProduct(): Promise<void> {
        this.setState((state) => ({
            selectedProduct: { data: null, status: LoadStatus.success },
        }));
    }

    async fetchProduct(id: number): Promise<void> {
        try {
            this.setState((state) => ({
                selectedProduct: { data: null, status: LoadStatus.loading },
            }));

            const product = await this.productService.getProductById(id);

            this.setState((state) => ({
                selectedProduct: {
                    data: product,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            this.setState((state) => ({
                selectedProduct: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async saveProduct(productCreationData: any): Promise<ProductDTO> {
        try {
            this.setState((state) => ({
                savingProduct: {
                    data: null,
                    status: LoadStatus.loading,
                },
            }));

            const company = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (!company?.data?.id) {
                this.setState((state) => ({
                    savingProduct: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Empresa não selecionada',
                    },
                }));
                return;
            }

            const product = await this.productService.saveProduct({
                ...productCreationData,
                company: {
                    id: company?.data?.id,
                },
            });

            this.fetchProducts();

            this.setState((state) => ({
                savingProduct: { data: product, status: LoadStatus.success },
            }));

            return product;
        } catch (e) {
            this.setState((state) => ({
                savingProduct: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async update(productData: any): Promise<ProductDTO> {
        try {
            const product = await this.productService.update({
                ...productData,
            });

            this.fetchProducts();

            this.setState((state) => ({
                selectedProduct: { data: product, status: LoadStatus.success },
                products: {
                    ...state.products,
                    data: state.products.data.map((each) =>
                        each.id === product.id ? product : each
                    ),
                },
            }));

            return product;
        } catch (e) {
            this.setState((state) => ({
                selectedProduct: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async deleteSelected(): Promise<void> {
        const selectedProduct = await firstValueFrom(
            this.selectState((state) => state.selectedProduct.data)
        );

        if (!selectedProduct?.id) {
            return;
        }

        await this.productService.delete(selectedProduct.id);

        this.setState((state) => ({
            selectedProduct: {
                status: LoadStatus.success,
            },
            products: {
                ...state.products,
                data: filter(state.products.data, (item) => item.id !== selectedProduct.id),
            },
        }));
    }

    async addPhotoOnSelected(photo: string): Promise<void> {
        const selectedProduct = await firstValueFrom(
            this.selectState((state) => state.selectedProduct.data)
        );

        if (!selectedProduct?.id) {
            return;
        }

        await this.productService.addPhoto(selectedProduct.id, photo);
        this.fetchProduct(selectedProduct.id);
    }

    async removePhotoOnSelected(photoId: number): Promise<void> {
        const selectedProduct = await firstValueFrom(
            this.selectState((state) => state.selectedProduct.data)
        );

        if (!selectedProduct?.id) {
            return;
        }

        await this.productService.removePhoto(photoId);
        this.fetchProduct(selectedProduct.id);
    }
}
