import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { CompanyDTO } from '../dto/company.dto';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { CompanyService } from '../service/company.service';
import { UserStore } from './user.store';

export interface CompanyState {
    companies: ObjectWithLoaderDTO<CompanyDTO[]>;
    selectedCompany: ObjectWithLoaderDTO<CompanyDTO>;
    savingCompany: ObjectWithLoaderDTO<CompanyDTO>;
    users: ObjectWithLoaderDTO<any[]>;
}

@Injectable()
export class CompanyStore extends NgSimpleStateBaseStore<CompanyState> {
    constructor(
        injector: Injector,
        private userStore: UserStore,
        private companyService: CompanyService
    ) {
        super(injector);
    }

    initialState(): CompanyState {
        return {
            companies: new ObjectWithLoaderDTO<CompanyDTO[]>(),
            selectedCompany: new ObjectWithLoaderDTO<CompanyDTO>(),
            savingCompany: new ObjectWithLoaderDTO<CompanyDTO>(),
            users: new ObjectWithLoaderDTO<any>(),
        };
    }

    getCompanies(): Observable<ObjectWithLoaderDTO<CompanyDTO[]>> {
        return this.selectState((state) => state.companies);
    }

    getUsers(): Observable<ObjectWithLoaderDTO<any[]>> {
        return this.selectState((state) => state.users);
    }

    getSelectedCompanie(): Observable<ObjectWithLoaderDTO<CompanyDTO>> {
        return this.selectState((state) => state.selectedCompany);
    }

    getSavingCompany(): Observable<ObjectWithLoaderDTO<CompanyDTO>> {
        return this.selectState((state) => state.savingCompany);
    }

    deselectCompany(): void {
        this.setState((state) => ({
            ...state,
            selectedCompany: new ObjectWithLoaderDTO<CompanyDTO>(),
        }));
    }

    async fetchCompanyStripeUrl(): Promise<string> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        const url = await this.companyService.getCompaniesStripeLoginLink(
            selectedCompany?.id
        );

        return url;
    }

    async fetchUsers(): Promise<void> {
        try {
            this.setState((state) => ({
                users: { ...state.users, status: LoadStatus.loading },
            }));

            const selectedCompanyWithLoader = await firstValueFrom(
                this.getSelectedCompanie()
            );

            if (selectedCompanyWithLoader.data?.id == null) {
                this.setState((state) => ({
                    users: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Empresa não selecionada',
                    },
                }));
            }

            const users = await this.companyService.getUsersByCompany(
                selectedCompanyWithLoader.data?.id
            );

            this.setState((state) => ({
                users: { data: users, status: LoadStatus.success },
            }));
        } catch (e) {
            this.setState((state) => ({
                users: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async saveUserWithEmail(userEmail: string, roleId: number): Promise<void> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        await this.companyService.addUserToCompany({
            userEmail,
            roleId,
            companyId: selectedCompany?.id,
        });

        this.fetchUsers();
    }

    async saveUser(userId: number, roleId: number): Promise<void> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        await this.companyService.addUserToCompany({
            userId,
            roleId,
            companyId: selectedCompany?.id,
        });

        this.fetchUsers();
    }

    async deleteUser(userId: number): Promise<void> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        await this.companyService.deleteUserFromCompany(
            userId,
            selectedCompany?.id
        );

        this.fetchUsers();
    }

    async fetchCompanies(): Promise<void> {
        try {
            this.setState((state) => ({
                companies: { ...state.companies, status: LoadStatus.loading },
            }));

            const userWithLoader = await firstValueFrom(
                this.userStore.getUser()
            );

            if (userWithLoader.data?.id == null) {
                this.setState((state) => ({
                    companies: {
                        data: null,
                        status: LoadStatus.error,
                        message: 'Usuário não está logado',
                    },
                }));
            }

            const companies = await this.companyService.getCompaniesByUserId(
                userWithLoader.data?.id
            );

            this.setState((state) => ({
                companies: { data: companies, status: LoadStatus.success },
            }));
        } catch (e) {
            this.setState((state) => ({
                companies: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async fetchCompany(companyId: number): Promise<void> {
        try {
            this.setState((state) => ({
                selectedCompany: {
                    data: null,
                    status: LoadStatus.loading,
                },
            }));

            const company = await this.companyService.getCompanyById(companyId);

            this.setState((state) => ({
                selectedCompany: { data: company, status: LoadStatus.success },
            }));
        } catch (e) {
            this.setState((state) => ({
                selectedCompany: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async save(companyCreationData: any): Promise<CompanyDTO> {
        this.setState((state) => ({
            savingCompany: {
                data: null,
                status: LoadStatus.loading,
            },
        }));

        const user = await firstValueFrom(this.userStore.getUser());

        if (!user?.data?.id) {
            this.setState((state) => ({
                savingCompany: {
                    data: null,
                    status: LoadStatus.error,
                    message: 'Usuário não está logado',
                },
            }));
            return;
        }

        const company = await this.companyService.save({
            ...companyCreationData,
            userId: user?.data?.id,
        });

        this.fetchCompanies();

        this.setState((state) => ({
            savingCompany: { data: company, status: LoadStatus.success },
        }));

        return company;
    }

    async update(companyCreationData: any): Promise<CompanyDTO> {
        try {
            const company = await this.companyService.update({
                ...companyCreationData,
            });

            this.fetchCompanies();
            this.setState((state) => ({
                selectedCompany: { data: company, status: LoadStatus.success },
            }));

            this.setState((state) => ({
                savingCompany: { data: company, status: LoadStatus.success },
            }));

            return company;
        } catch (e) {
            this.setState((state) => ({
                savingCompany: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }

    async deleteSelected(): Promise<void> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        await this.companyService.delete(selectedCompany.id);

        this.setState((state) => ({
            selectedCompany: {
                status: LoadStatus.success,
            },
        }));
    }

    async addPhotoOnSelected(photo: string): Promise<void> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        await this.companyService.addPhoto(selectedCompany.id, photo);
        this.fetchCompany(selectedCompany.id);
    }

    async removePhotoOnSelected(photoId: number): Promise<void> {
        const selectedCompany = await firstValueFrom(
            this.selectState((state) => state.selectedCompany.data)
        );

        if (!selectedCompany?.id) {
            return;
        }

        await this.companyService.removePhoto(photoId);
        this.fetchCompany(selectedCompany.id);
    }
}
