import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { firstValueFrom, from, lastValueFrom, Observable } from 'rxjs';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private afAuth: AngularFireAuth) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return from(this.handle(req, next));
    }

    async handle(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Promise<HttpEvent<any>> {
        const user = await this.afAuth.currentUser;
        const token = await user?.getIdToken();

        const authReq = req.clone({
            headers: req.headers.append(
                'X-Authorization-Firebase',
                token ?? ''
            ),
        });

        return await lastValueFrom(next.handle(authReq));
    }
}
