import { Injectable, Injector } from '@angular/core';
import { NgSimpleStateBaseStore } from 'ng-simple-state';
import { firstValueFrom, Observable } from 'rxjs';
import { ChatMessageDTO } from '../dto/chat-message.dto';
import { ChatSessionCompactDTO, ChatSessionDTO } from '../dto/chat-session.dto';
import { CompanyBranchDTO } from '../dto/company-branch.dto';
import { LoadStatus, ObjectWithLoaderDTO } from '../dto/object-with-loader.dto';
import { ChatSessionService } from '../service/chat-session.service';
import { CompanyStore } from './company.store';

export interface ChatSessionState {
    sessions: ObjectWithLoaderDTO<ChatSessionCompactDTO[]>;
    selectedSession: ObjectWithLoaderDTO<ChatSessionDTO>;
}

@Injectable()
export class ChatSessionStore extends NgSimpleStateBaseStore<ChatSessionState> {
    constructor(
        injector: Injector,
        private companyStore: CompanyStore,
        private chatSessionService: ChatSessionService
    ) {
        super(injector);
    }

    initialState(): ChatSessionState {
        return {
            sessions: new ObjectWithLoaderDTO<ChatSessionCompactDTO[]>(),
            selectedSession: new ObjectWithLoaderDTO<ChatSessionDTO>(),
        };
    }

    getSessions(): Observable<ObjectWithLoaderDTO<ChatSessionCompactDTO[]>> {
        return this.selectState((state) => state.sessions);
    }

    getSelectedSession(): Observable<ObjectWithLoaderDTO<CompanyBranchDTO>> {
        return this.selectState((state) => state.selectedSession);
    }

    cleanSelected(): void {
        this.setState((state) => ({
            selectedSession: new ObjectWithLoaderDTO<ChatSessionDTO>(),
        }));
    }

    async fetchSessions(noLoading: boolean = false): Promise<void> {
        try {
            if (!noLoading) {
                this.setState((state) => ({
                    sessions: { data: null, status: LoadStatus.loading },
                }));
            }

            const selectedCompany = await firstValueFrom(
                this.companyStore.getSelectedCompanie()
            );

            if (selectedCompany.data?.id == null) {
                if (!noLoading) {
                    this.setState((state) => ({
                        sessions: {
                            data: null,
                            status: LoadStatus.error,
                            message: 'Nenhuma empresa selectionada',
                        },
                    }));
                }
            }

            const sessions = await this.chatSessionService.getByCompayId(
                selectedCompany.data?.id
            );

            this.setState((state) => ({
                sessions: {
                    data: sessions,
                    status: LoadStatus.success,
                },
            }));
        } catch (e) {
            if (!noLoading) {
                this.setState((state) => ({
                    sessions: {
                        data: null,
                        status: LoadStatus.error,
                        message: e.message,
                    },
                }));
            }
        }
    }

    async fetchSession(sessionId: number): Promise<void> {
        try {
            this.setState((state) => ({
                selectedSession: {
                    data: null,
                    status: LoadStatus.loading,
                },
            }));

            const session = await this.chatSessionService.getById(sessionId);

            this.setState((state) => ({
                selectedSession: { data: session, status: LoadStatus.success },
            }));
        } catch (e) {
            this.setState((state) => ({
                selectedSession: {
                    data: null,
                    status: LoadStatus.error,
                    message: e.message,
                },
            }));
        }
    }
}
