import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { NgSimpleStateModule } from 'ng-simple-state';
import { environment } from '../environments/environment';
import { UserStore } from './core/store/user.store';
import { CompanyStore } from './core/store/company.store';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { CommonModule } from '@angular/common';
import { ProductStore } from './core/store/product.store';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './core/interceptor/header.interceptor';
import { lastValueFrom } from 'rxjs';
import { NgxMaskModule } from 'ngx-mask';
import { ProductCategoryStore } from './core/store/product-category.store';
import { ServiceCategoryStore } from './core/store/service-category.store';
import { ServiceStore } from './core/store/service.store';
import { CompanyBranchStore } from './core/store/company-branch.store';
import { ChatSessionStore } from './core/store/chat-session.store';
import { ChatMessageStore } from './core/store/chat-message.store';
import { QuillModule } from 'ngx-quill';
import { VerificationStore } from './core/store/verification.store';
import { RoleStore } from './core/store/role.store';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

const appConfigFactory =
    (userStore: UserStore): (() => Promise<any>) =>
    (): Promise<any> =>
        lastValueFrom(userStore.getUser());

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Core module of your application
        CoreModule.forRoot(),

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        NgxMaskModule.forRoot(),
        MarkdownModule.forRoot({}),
        NgSimpleStateModule.forRoot({
            enableDevTool: !environment.production, // Enable Redux DevTools only in development mode
            enableLocalStorage: false,
            persistentStorage: 'session',
        }),
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                    ['blockquote'],
                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                    [{ direction: 'rtl' }], // text direction
                    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ align: [] }],
                    ['clean'], // remove formatting button
                ],
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        UserStore,
        CompanyStore,
        RoleStore,
        VerificationStore,
        ProductStore,
        ProductCategoryStore,
        ServiceStore,
        ServiceCategoryStore,
        CompanyBranchStore,
        ChatSessionStore,
        ChatMessageStore,
        {
            provide: APP_INITIALIZER,
            useFactory: (): ((userStore: UserStore) => () => Promise<any>) =>
                appConfigFactory,
            deps: [UserStore],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderInterceptor,
            multi: true,
        },
    ],
})
export class AppModule {}
