import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { appConfig } from './core/config/app.config';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'admin' },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
                title: `${appConfig.baseTitle} - Início`,
            },
        ],
    },

    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'admin' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
                title: `${appConfig.baseTitle} - Entrar`,
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
                title: `${appConfig.baseTitle} - Cadastrar`,
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
                title: `${appConfig.baseTitle} - Saindo`,
            },
        ],
    },

    // Admin routes
    {
        path: 'admin',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        'app/modules/admin/select-company/select-company.module'
                    ).then((m) => m.SelectCompanyModule),
                title: `${appConfig.baseTitle} - Selecione uma empresa`,
            },
            {
                path: 'company/:companyId',
                resolve: {
                    initialData: InitialDataResolver,
                },
                children: [
                    { path: '', pathMatch: 'full', redirectTo: 'general' },
                    {
                        path: 'general',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/company/company-general/company-general.module'
                            ).then((m) => m.CompanyGeneralModule),
                        title: `${appConfig.baseTitle} - Geral`,
                    },
                    {
                        path: 'product',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/company/company-product/company-product.module'
                            ).then((m) => m.CompanyProductModule),
                        title: `${appConfig.baseTitle} - Produtos`,
                    },
                    {
                        path: 'service',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/company/company-service/company-service.module'
                            ).then((m) => m.CompanyServiceModule),
                        title: `${appConfig.baseTitle} - Serviços`,
                    },
                    {
                        path: 'chat',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/company/company-chat/company-chat.module'
                            ).then((m) => m.CompanyChatModule),
                        title: `${appConfig.baseTitle} - Chat`,
                    },
                    {
                        path: 'summary',
                        loadChildren: () =>
                            import(
                                'app/modules/admin/company/company-summary/company-summary.module'
                            ).then((m) => m.CompanySummaryModule),
                        title: `${appConfig.baseTitle} - Sumário`,
                    },
                ],
            },
        ],
    },
];
