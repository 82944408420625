import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, retry } from 'rxjs';
import { CompanyBranchDTO } from '../dto/company-branch.dto';

@Injectable({
    providedIn: 'root',
})
export class CompanyBranchService {
    constructor(private httpClient: HttpClient) {}

    getByCompayId(companyId: number): Promise<CompanyBranchDTO[]> {
        const branches$ = this.httpClient
            .get<CompanyBranchDTO[]>(
                `${environment.backBaseUrl}/company-branch/company/${companyId}`
            )
            .pipe(retry(3));
        return lastValueFrom(branches$);
    }

    save(branch: CompanyBranchDTO): Promise<CompanyBranchDTO> {
        const branch$ = this.httpClient
            .put<CompanyBranchDTO>(
                `${environment.backBaseUrl}/company-branch`,
                branch
            )
            .pipe(retry(3));
        return lastValueFrom(branch$);
    }

    update(branch: CompanyBranchDTO): Promise<CompanyBranchDTO> {
        const branch$ = this.httpClient
            .post<CompanyBranchDTO>(
                `${environment.backBaseUrl}/company-branch`,
                branch
            )
            .pipe(retry(3));
        return lastValueFrom(branch$);
    }

    delete(id: number): Promise<void> {
        const request$ = this.httpClient
            .delete<void>(`${environment.backBaseUrl}/company-branch/id/${id}`)
            .pipe(retry(3));
        return lastValueFrom(request$);
    }
}
