import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { lastValueFrom, map, retry } from 'rxjs';
import { UserDTO } from '../dto/user.dto';
import { VerificationDTO } from '../dto/verification.dto';

@Injectable({
    providedIn: 'root',
})
export class VerificationService {
    constructor(private httpClient: HttpClient) {}

    getAll(): Promise<VerificationDTO[]> {
        const verifications$ = this.httpClient
            .get<VerificationDTO[]>(`${environment.backBaseUrl}/verification`)
            .pipe(retry(3))
            .pipe(map((res) => res.map((each) => new VerificationDTO(each))));
        return lastValueFrom(verifications$);
    }
}
